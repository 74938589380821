import React, { useMemo } from 'react'
import { useLocation } from '@reach/router'
import { Global } from '@emotion/react'
import { navigate } from 'gatsby'
import { globalStyles } from '../../styles'
import SiteMetadata from '../site-metadata'
import PageMetadata from '../page-metadata'
import LazyModal from './lazy-modal'
import useOverflowYHidden from '../../hooks/use-overflow-y-hidden'

const modalStyle = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: 0,
    borderRadius: 0,
    padding: 0,
  },
}

const Modal = ({ children, modalBackgroundPath, metadata = {} }) => {
  const location = useLocation()
  useOverflowYHidden()

  const modal = useMemo(
    () => ({
      close: ({ useBack = false } = {}) => {
        const referrer = location?.state?.referrer
        if (useBack && referrer) {
          return navigate(-1)
        }

        return navigate(referrer || modalBackgroundPath || '/', {
          state: {
            noScroll: true,
          },
        })
      },
    }),
    [modalBackgroundPath, location],
  )

  return (
    <div>
      <Global styles={globalStyles} />
      <SiteMetadata location={location} />
      <PageMetadata {...metadata} />
      <LazyModal
        isOpen={true}
        style={modalStyle}
        onRequestClose={() => modal.close({ useBack: true })}
        contentLabel="Modal"
      >
        {children(modal)}
      </LazyModal>
    </div>
  )
}

export default Modal
