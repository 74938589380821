import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { withAttrs } from '../../utils'
import BaseButton from '../shared/button'

const ThemeSwitch = styled(
  withAttrs(BaseButton, { variant: 'outline', size: 'small' }),
)`
  width: 4.75rem;
  padding: ${p => `${p.theme.space[2]} ${p.theme.space[4]}`};
  background: transparent;

  :before {
    content: '${p => (p.themeName === 'dark' ? 'Light' : 'Dark')}';
  }

  ${mediaQueries.md} {
    width: 5.875rem;

    :before {
      content: '${p => (p.themeName === 'dark' ? 'To light' : 'To dark')}';
    }
  }
`
export default ThemeSwitch
