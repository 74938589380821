import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { withAttrs } from '../../utils'

const PreviewFrame = styled(withAttrs('iframe', { frameBorder: 0 }))`
  display: block;
  flex: 1;

  ${mediaQueries.md} {
    ${p =>
      p.device === 'mobile' &&
      `
      flex: none;
      width: 25.875rem;
      height: 41.75rem;
      margin: auto;
      border: 1px solid ${p.theme.colors.gray[5]};
  `}
  }
`

export default PreviewFrame
