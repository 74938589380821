import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mediaQueries } from '../../theme'
import { withAttrs } from '../../utils'
import { H4 } from '../shared/type'
import Button from '../shared/button'
import Loader from '../shared/loader'

const OuterContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

const Title = styled(H4)`
  margin: ${p => `0 0 ${p.theme.space[5]}`};
`.withComponent('h1')

const Description = styled.p`
  margin: 0 0 ${p => p.theme.space[6]};
  font-size: ${p => p.theme.fontSizes[1]};
`

const StartButton = styled(withAttrs(Button, { type: 'button' }))`
  width: 100%;
  min-height: 3.75rem;
  cursor: pointer;
`

const MobileCta = styled(withAttrs(Button, { type: 'button', size: 'small' }))`
  padding: ${p => `${p.theme.space[2]} ${p.theme.space[6]}`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :before {
    content: 'Take it';

    ${mediaQueries.md} {
      content: 'Take this template';
    }
  }
`

const Cta = ({ className, onAction, showLoader }) => (
  <OuterContainer className={className}>
    <Title>Like the template?</Title>
    <Description>
      Use the template as is or make any changes to create a unique form.
    </Description>
    <StartButton onClick={onAction}>
      {showLoader ? <Loader /> : 'Take this template'}
    </StartButton>
  </OuterContainer>
)

const PreviewCta = ({ className, onAction, showLoader }) => (
  <Fragment>
    <MobileCta
      onClick={() => onAction({ source: 'mobile' })}
      className={className}
      css={css`
        ${mediaQueries.lg} {
          display: none;
        }
      `}
    />
    <Cta
      showLoader={showLoader}
      onAction={() => onAction({ source: 'desktop' })}
      className={className}
      css={css`
        display: none;
        ${mediaQueries.lg} {
          display: block;
        }
      `}
    />
  </Fragment>
)

export default PreviewCta
