import React from 'react'
import styled from '@emotion/styled'
import { DesktopIcon, MobileIcon } from '../../assets/icons/common'
import { withAttrs } from '../../utils'

const Container = styled.div``

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid
    ${p => (p.selected ? p.theme.colors.black[0] : 'transparent')};
  border-radius: ${p => p.theme.space[2]};
  background: transparent;
  cursor: pointer;

  :hover {
    border-color: ${p => !p.selected && p.theme.colors.black[0]};
  }
`

const Desktop = styled(withAttrs(Button, { children: <DesktopIcon /> }))``

const Mobile = styled(withAttrs(Button, { children: <MobileIcon /> }))`
  margin-left: ${p => p.theme.space[2]};
`

const DeviceSwitch = ({ device, onChange, className }) => (
  <Container className={className}>
    <Desktop
      selected={device === 'desktop'}
      onClick={() => onChange('desktop')}
    />

    <Mobile selected={device === 'mobile'} onClick={() => onChange('mobile')} />
  </Container>
)

export default DeviceSwitch
