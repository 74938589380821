import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

const Flipper = styled.div`
  position: relative;
`

const Card = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: ${p => p.flipSpeed}s;
  backface-visibility: hidden;
`

const Back = styled(Card)`
  position: ${p => (p.isFlipped ? 'relative' : 'absolute')};
  transform: rotateY(${p => (p.isFlipped ? 0 : -180)}deg);
`

const Front = styled(Card)`
  position: ${p => (p.isFlipped ? 'absolute' : 'relative')};
  transform: rotateY(${p => (p.isFlipped ? 180 : 0)}deg);
`

const CardFlip = ({ flipSpeed = 0.75, children, className, ...props }) => {
  const [isFlipped, setFlipped] = useState(props.isFlipped)

  useEffect(() => {
    if (props.isFlipped !== isFlipped) {
      setFlipped(props.isFlipped)
    }
  }, [props.isFlipped, isFlipped])

  return (
    <Flipper className={className}>
      <Front flipSpeed={flipSpeed} isFlipped={isFlipped}>
        {children[0]}
      </Front>
      <Back flipSpeed={flipSpeed} isFlipped={isFlipped}>
        {children[1]}
      </Back>
    </Flipper>
  )
}

export default CardFlip
