import React, { useEffect, Fragment } from 'react'
import styled from '@emotion/styled'
import mousetrap from 'mousetrap'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-circle-right.svg'
import { mediaQueries } from '../../theme'
import { withAttrs } from '../../utils'
import BaseLink from '../shared/link'

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  min-width: 6.75rem;
`

const Title = styled.span`
  margin: 0 ${p => p.theme.space[2]};
  font-size: ${p => p.theme.fontSizes[1]};
  line-height: ${p => p.theme.lineHeights.solid};
`

const RightArrow = styled(ArrowIcon)`
  display: block;
  ${mediaQueries.lg} {
    width: ${p => p.theme.space[8]};
    height: ${p => p.theme.space[8]};
    &:hover [data-id='background'] {
      fill: ${p => p.theme.colors.black[0]};
    }
    &:hover [data-id='arrow'] {
      fill: ${p => p.theme.colors.white};
    }
  }
`

const LeftArrow = styled(RightArrow)`
  transform: rotateY(180deg);
`

const Link = styled(BaseLink)`
  cursor: pointer;
`

const GalleryLink = styled(
  withAttrs(Link, { variant: 'nav', to: '/templates/' }),
)`
  font-size: ${p => p.theme.fontSizes[1]};
`

const useNavigation = (current, items = []) => {
  const len = items.length
  const idx = items.indexOf(current)
  const title = `${idx + 1} of ${len}`
  const prev = () => items[(idx + len - 1) % len]
  const next = () => items[(idx + 1) % len]

  return [title, prev, next]
}

const PreviewNavigation = ({ className, current, items, onNavigate }) => {
  const [title, prev, next] = useNavigation(current, items)

  useEffect(() => {
    mousetrap.bind(`left`, () => onNavigate(prev()))
    mousetrap.bind(`right`, () => onNavigate(next()))

    return () => {
      mousetrap.unbind(`left`)
      mousetrap.unbind(`right`)
    }
  }, [prev, next, onNavigate])

  return (
    <Container className={className}>
      {items.length > 0 ? (
        <Fragment>
          <Link onClick={() => onNavigate(prev())}>
            <LeftArrow />
          </Link>
          <Title>{title}</Title>
          <Link onClick={() => onNavigate(next())}>
            <RightArrow />
          </Link>
        </Fragment>
      ) : (
        <GalleryLink>Show all &#10142;</GalleryLink>
      )}
    </Container>
  )
}

export default PreviewNavigation
