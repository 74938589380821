import styled from '@emotion/styled'
import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { mediaQueries } from '../../theme'
import { withAttrs, isInsideIframe } from '../../utils'
import { isAuthenticated } from '../../utils/auth'
import { APP_URL } from '../../environment'
import Modal from '../shared/modal'
import RegistrationComponent from '../auth/registration-compact'
import LoginComponent from '../auth/login'
import CardFlip from '../shared/card-flip'
import PreviewNavigation from './preview-navigation'
import PreviewCta from './preview-cta'
import BaseDeviceSwitch from './device-switch'
import PreviewFrame from './preview-frame'
import BaseThemeSwitch from './theme-switch'

const Login = styled(LoginComponent)`
  display: none;
  ${mediaQueries.lg} {
    display: block;
    grid-row: 1;
    grid-column: 1/4;
  }
`

const RegistrationCompact = styled(RegistrationComponent)`
  display: none;
  ${mediaQueries.lg} {
    display: block;
    grid-row: 2;
    grid-column: 1/4;
  }
`

const OuterContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;

  ${mediaQueries.lg} {
    flex-flow: row nowrap;
    overflow: hidden;
  }
`

const DeviceSwitch = styled(BaseDeviceSwitch)`
  display: none;

  ${mediaQueries.md} {
    display: flex;
    grid-column: 2;
  }

  ${mediaQueries.lg} {
    grid-column: 1;
  }
`

const Panel = styled.div`
  display: grid;
  grid-template-columns: 1fr 4.75rem 5.4375rem 1fr;
  grid-template-rows: auto 1fr auto;
  grid-column-gap: ${p => p.theme.space[4]};
  align-items: center;
  background-color: ${p => p.theme.colors.preview.background};

  ${mediaQueries.md} {
    grid-template-columns: 1fr 5.5rem 5.875rem 10.725rem 1fr;
    grid-column-gap: ${p => p.theme.space[5]};
  }

  ${mediaQueries.lg} {
    grid-template-columns: 5.5rem 6.375rem auto;
    height: 100%;
    width: 20rem;
    justify-items: stretch;
  }
`

const FrontPanel = styled(Panel)`
  padding: ${p => `${p.theme.space[3]} ${p.theme.space[4]}`};

  ${mediaQueries.lg} {
    padding: ${p => p.theme.space[6]};
  }
`

const BackPanel = styled(Panel)`
  padding: ${p =>
    `${p.theme.space[8]} ${p.theme.space[6]} ${p.theme.space[6]}`};
`

const Navigation = styled(PreviewNavigation)`
  grid-column: 1;
  grid-row: 1;
  justify-self: flex-start;

  ${mediaQueries.lg} {
    grid-column: 1/4;
    grid-row: 3;
    justify-self: auto;
  }
`

const Cta = styled(PreviewCta)`
  ${mediaQueries.md} {
    grid-column: 4;
  }

  ${mediaQueries.lg} {
    grid-row: 2;
    grid-column: 1/4;
  }
`

const Frame = styled(PreviewFrame)`
  ${mediaQueries.lg} {
    order: -1;
  }
`

const ThemeSwitch = styled(BaseThemeSwitch)`
  justify-self: flex-end;
  grid-column: 2;

  ${mediaQueries.md} {
    grid-column: 3;
  }

  ${mediaQueries.lg} {
    grid-column: 2;
    justify-self: center;
  }
`

const Close = styled(withAttrs(CloseIcon, { as: 'a' }))`
  grid-row: 1;
  grid-column: 4;
  justify-self: flex-end;
  cursor: pointer;

  ${mediaQueries.md} {
    grid-column: 5;
  }

  ${mediaQueries.lg} {
    grid-column: 3;
    width: 1rem;
    height: 1rem;
    transition: transform 0.3s;

    :hover {
      transform: rotate(90deg);
    }
  }
`

const PreviewModal = ({
  templateId,
  templates = [],
  defaultTheme,
  defaultDevice,
  modalBackgroundPath,
  ...rest
}) => {
  const [theme, setTheme] = useState(defaultTheme)
  const [device, setDevice] = useState(defaultDevice)
  const [loading, setLoading] = useState(false)
  const cloneRelativeUrl = `/clone/${templateId}?colorMode=${theme}`
  const cloneUrl = `${APP_URL}${cloneRelativeUrl}`
  const previewUrl = `${APP_URL}/preview/${templateId}?colorMode=${theme}`

  const [isFlipped, setFlipped] = useState(false)

  const flipCard = () => setFlipped(!isFlipped)

  const handleThemeToggle = () => setTheme(theme === 'dark' ? 'light' : 'dark')

  const handleDeviceChange = device => setDevice(device)

  const handleNavigate = tplId =>
    navigate(`/p/${tplId}`, {
      state: { modal: true, templates, noReferrer: true },
      replace: true,
    })

  const handleAction = ({ source }) => {
    // embedded in iframe
    if (isInsideIframe()) {
      setLoading(true)
      return window.parent.postMessage(
        {
          event: 'template-selected',
          widgetId: templateId,
          colorMode: theme,
        },
        '*',
      )
    }

    if (isAuthenticated()) {
      window.location = cloneUrl
      return
    }

    if (source === 'mobile') {
      return navigate(`/start/?next=${encodeURIComponent(cloneRelativeUrl)}`)
    } else {
      flipCard()
    }
  }

  return (
    <Modal modalBackgroundPath={modalBackgroundPath || '/templates/'} {...rest}>
      {modal => (
        <OuterContainer>
          <CardFlip isFlipped={isFlipped}>
            <FrontPanel>
              <DeviceSwitch device={device} onChange={handleDeviceChange} />
              <ThemeSwitch themeName={theme} onClick={handleThemeToggle} />
              <Navigation
                current={templateId}
                items={templates}
                onNavigate={handleNavigate}
              />
              <Cta onAction={handleAction} showLoader={loading} />
              <Close onClick={() => modal.close({ useBack: true })} />
            </FrontPanel>
            <BackPanel>
              <Login next={cloneRelativeUrl} />
              <RegistrationCompact next={cloneRelativeUrl} />
              <Close onClick={flipCard} />
            </BackPanel>
          </CardFlip>

          <Frame device={device} src={previewUrl} key={previewUrl + device} />
        </OuterContainer>
      )}
    </Modal>
  )
}

export default PreviewModal
