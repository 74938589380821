import React from 'react'
import PreviewModal from '../components/preview/preview-modal'

const getUrlParams = url => {
  try {
    const currentUrl = new URL(url)
    return {
      colorMode: currentUrl.searchParams.get('colorMode'),
      device: currentUrl.searchParams.get('device'),
    }
  } catch {
    return { colorMode: null, device: null }
  }
}

export default function WidgetTemplate({ templateId, location }) {
  const urlParams = getUrlParams(location.href)

  const templates = location.state?.templates || []
  const defaultDevice =
    urlParams.device || location.state?.defaultDevice || 'desktop'
  const defaultTheme =
    urlParams.colorMode || location.state?.defaultTheme || 'light'

  const metadata = {
    title: 'Getform – The Most Intuitive Online Form Builder',
    description:
      'Use custom online forms to conduct research and generate leads. Create a form, copy a direct link to it, and share that link anywhere. No website required.',
    keywords: 'online form builder',
    twitterCard: 'summary_large_image',
    robots: 'noindex',
  }

  return (
    <PreviewModal
      templateId={templateId}
      templates={templates}
      metadata={metadata}
      defaultDevice={defaultDevice}
      defaultTheme={defaultTheme}
    />
  )
}
