import { useEffect } from 'react'

const useOverflowYHidden = () => {
  useEffect(() => {
    document.querySelector('html').style.overflowY = 'hidden'
    return () => {
      document.querySelector('html').style.overflowY = 'auto'
    }
  })
}

export default useOverflowYHidden