import React from 'react'

export const slugify = value => value.toLowerCase().replace(/\s+/g, '-')

export const withAttrs = (Component, attrs) => props =>
  <Component {...attrs} {...props} />

export function isInsideIframe() {
  try {
    return typeof window !== 'undefined' && window.self !== window.top
  } catch (e) {
    return true
  }
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
})

export function formatNumber(value, sep = ' ') {
  return formatter
    .formatToParts(value)
    .filter(part => part.type === 'integer')
    .map(part => part.value)
    .join(sep)
}

export const chunkArray = (array, size) =>
  array.reduce((acc, _, i) => {
    if (i % size === 0) acc.push(array.slice(i, i + size))
    return acc
  }, [])
