import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import Divider from '../shared/divider'
import Link from '../shared/link'
import { GoogleButton } from './social-buttons'
import RegistrationFormComponent from './registration-form'

const RegistrationForm = styled(RegistrationFormComponent)`
  margin-bottom: ${p => p.theme.space[6]};
`

const Note = styled.p`
  font-size: ${p => p.theme.fontSizes[0]};
  text-align: center;
  max-width: 14.5rem;
  margin: 0 auto;
`

const GoogleSignUpButton = styled(GoogleButton)`
  width: 100%;
`

const Title = styled.h1`
  text-align: left;
  ${mediaQueries.sm} {
    font-size: ${p => p.theme.fontSizes[5]};
    margin: 0 0 ${p => p.theme.space[5]} 0;
  }
`

const Container = styled.div``

const RegistrationCompact = ({ className, next }) => (
  <Container className={className}>
    <Title>Create an account</Title>
    <GoogleSignUpButton next={next}>Sign up with Google</GoogleSignUpButton>
    <Divider size="small">or sign up with email</Divider>
    <RegistrationForm next={next} password={false} />
    <Note>
      By signing up, you agree to the{' '}
      <Link variant="fill" to="/terms/">
        Terms of service
      </Link>{' '}
      and{' '}
      <Link variant="fill" to="/privacy/">
        Privacy policy
      </Link>
    </Note>
  </Container>
)

export default RegistrationCompact
